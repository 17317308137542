import React from "react";
import Header from "@components/header";
import Head from "next/head";

import Help from "@components/home/Help";
import Intro from "@components/home/Intro";
import Community from "@components/home/Community";
import Everyone from "@components/home/Everyone";
import Impact from "@components/home/Impact";

import Footer from "@components/footer";



const Index = () => {
  return (
    <>
      <Head>
        <title>Uncovered: Cold Case Files for Murdered or Missing People</title>
      </Head>
      <Header />
      <main className="bg-gray-100">
        <Intro />
        <Help/>
        <Community/>
        <Everyone/>
        <Impact/>
      </main>
      <Footer/>
    </>
  );
};

export default Index;
