import React from "react";
import dynamic from "next/dynamic";
import Link from "next/link";

const WistiaWrapper = dynamic(() => import("@components/home/WistiaWrapper"), {
  ssr: false,
});

const Intro = () => {
  return (
    <div className="bg-[#F8F4F0]">
    <div className=" max-w-7xl w-full mx-auto pt-16 px-4 pb-24 ">
      <h1 className="text-2xl font-bold uppercase tracking-[9.5px] text-center mb-7">
        CROWDSOURCING COLD CASES
      </h1>
      <div className="relative">
        <h1 className="absolute inset-0 flex justify-center items-center text-[#C4C4C4] text-opacity-80 z-10 text-3xl xl:text-[5.68rem] xl:leading-[109px] text-center font-black">
          FOLLOW AND HELP SOLVE REAL-WORLD COLD CASES
        </h1>
        <div className=" max-w-6xl mx-auto px-1">
          <WistiaWrapper hashedId="t7xq83cjxg" />
        </div>
      </div> 
      <div className="max-w-6xl mx-auto px-1 flex mt-4 flex-col md:flex-row justify-between gap-10">
        <h3 className="text text-lg lg:text-2xl text-gray-580 lg:leading-[48px] w-full max-w-[680px] ">
          More than 200,000 unsolved cases have gone cold since 1980. Uncovered
          is the nation’s largest database of information powered by you.
        </h3>
        <div className="flex  flex-col items-center  lg:w-1/3">
          <h3 className=" text-2xl font-bold text-center"> Together, we can do better.</h3>
          <div className="flex mt-9 justify-center">
            <Link href="/cases">
              <a className="bg-red-600 flex-shrink-0 text-lg font-bold justify-center  text-center hover:bg-red-700 leading-[1.166] duration-300 py-3 px-14  ml-3 flex items-center mt-2 text-white transition  rounded-full">
                Find the cases
                <br />
                that matter to you
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Intro;
