import React from "react";
import dynamic from "next/dynamic";
import Link from "next/link";

const WistiaWrapper = dynamic(() => import("@components/home/WistiaWrapper"), {
  ssr: false,
});


const Community = () => {
  return (
    <div className="bg-[#424242]">
      <div className=" max-w-7xl w-full mx-auto pt-16 px-4 pb-24  text-white">
        <div className="flex justify-center flex-col gap-4">
          <h3 className="uppercase text-2xl font-bold text-center leading-9 text-[#f8f5f0] tracking-[9.5px]">
            BEYOND ENTERTAINMENT
          </h3>
          <h2 className=" text-[#ffa535] text-4xl leading-[54px] text-center font-black">
            The True Crime Community Can Do More
          </h2>
          <p className=" text-center leading-8">
            Uncovered is where the most passionate true crime enthusiasts can
            learn from and teach others. Dig deeper into the stories you’ve
            heard before, and encounter many others you haven’t, in ways that
            actually help. At some point, compassion causes entertainment to
            grow into a need for activism, contribution, and community to solve
            the stories of real people.
          </p>
        </div>

        <div className="mt-28 gap-16 flex flex-col lg:flex-row">
          <div className="w-full flex flex-col gap-16 ">
            <div>
              <h1 className="text-xl font-bold leading-9">Follow</h1>
              <p className=" leading-8 mt-2 text-base">
                As you find people whose cases speak to you, follow them. You’ll
                be notified of any updates, and sometimes there are
                contributions you can make to the cases themselves.
              </p>
            </div>
            <div>
              <h1 className="text-xl font-bold leading-9">Share</h1>
              <p className=" leading-8 mt-2 text-base">
                While solving every case is the ideal, they can take time.
                Outside of DNA, what breaks cases open is sharing information
                and using your platform to bring awareness to the cases that
                don’t get as much attention.
              </p>
            </div>
            <div>
              <h1 className="text-xl font-bold leading-9">Join</h1>
              <p className=" leading-8 mt-2 text-base">
                When you raise your hand, you make a choice to be counted, and
                counted upon, as part of a movement that calls upon collective
                impact to uncover answers in unsolved cases.
              </p>
            </div>{" "}
            <div>
              <h1 className="text-xl font-bold leading-9">Learn</h1>
              <p className=" leading-8 mt-2 text-base">
                Expand your skills with resources and opportunities to learn
                from experts, advocates, and others like you.
              </p>
            </div>{" "}
            <div>
              <h1 className="text-xl font-bold leading-9">Contribute</h1>
              <p className=" leading-8 mt-2 text-base">
                No one can do everything, but everyone can do something.
                Crowdsource the gaps, join a resource group, and put your true
                crime knowledge to use.
              </p>
            </div>
          </div>
          <div className="w-full h-full ">
            <WistiaWrapper hashedId="34s4kz9w4z" />
          </div>
        </div>
        <div className=" flex justify-center mt-16">
          <Link href="/join">
            <a className="bg-red-600 flex-shrink-0 text-lg font-bold justify-center  text-center hover:bg-red-700 leading-[1.166] duration-300 py-6 px-14  ml-3 flex items-center mt-2 text-white transition  rounded-full">
             Join the community
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Community;
