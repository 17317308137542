/* eslint-disable @next/next/no-img-element */
import React from "react";
import Link from "next/link";

const Impact = () => {
  return (
    <div className=" max-w-7xl w-full mx-auto py-24 px-4 pb-24">
      <h3 className="uppercase text-2xl font-bold text-center leading-9 text-gray-580 tracking-[9.5px]">
        TRUE CRIME, TRUE COMMUNITY, TRUE IMPACT
      </h3>
      <div className="flex justify-center mt-4 flex-col ">
        <div className="text-center mx-auto max-w-md  ">
          <h4 className="yellow-highlight  text-center !leading-[45px]  font-bold !text-4xl ">
            It ’s Never Been Easier –  Or More Important
          </h4>
        </div>
        <div className="flex mt-9 justify-center">
          <Link href="/join">
            <a className="bg-red-600 flex-shrink-0 text-lg font-bold justify-center  text-center hover:bg-red-700 leading-[1.166] duration-300 py-5 px-14  ml-3 flex items-center mt-2 text-white transition  rounded-full">
              Join Us
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Impact;
